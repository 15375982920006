import React, {Component, Fragment} from 'react'
import _ from 'lodash'
import {connect} from 'react-redux'
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom'
import {
    Badge,
    Input,
    List,
    Avatar,
    Button,
    Skeleton,
    Row,
    Col,
    Select,
    Card,
    Tabs
} from 'antd';
import ReactJson from 'react-json-view'

const { TabPane } = Tabs;
import moment from 'moment'
import Scroller from 'components/elements/Scroller'
import AdminLayout from 'components/elements/AdminLayout'
import SectionContent from 'components/elements/SectionContent'
import AppointmentRequests from './AppointmentRequests'
import RiskAssessment from './RiskAssessment'
import Notes from './Notes'
import {
    syncActions,
    syncStatusActions,
    integrationConfigActions
} from 'actions'
import { apiClient } from 'lib';
import Display from './Display'
import './style.less'

const TextArea = Input.TextArea;

class Fetch extends Component {
    constructor(props){
        super(props)
        this.state = {
            initLoading: true,
            loading: false,
            data: [],
            list: [],
        };
        this.search = this.search.bind(this)
        this.type = this.type.bind(this)
    }
    async componentDidMount(){
     //   await syncActions.fetch()
        this.syncInteval = setInterval(()=>{
            syncStatusActions.fetch()
        }, 10000)
        integrationConfigActions.fetch()

        this.setState({
            initLoading: false
        });
    }
    async fetchData(){
        let options = {
            contains: this.state.query
        }
        if(this.state.type && this.state.type !== 'all'){
            options.type = this.state.type
        }

        const response = await apiClient.organizations.operations.integrationEntities.fetch(options)
        if(response){
            this.setState({
                data: response.data
            })
        }
    }
    get data(){
        return this.state.data.map((data)=>({
            ...data,
            entityData: JSON.parse(data.entityData)
        }))
    }
    search(e){
        if(e.keyCode == 13){
            this.fetchData()
        }else{
            this.setState({
                query: e.target.value
            })
        }
    }
    type(type){
        this.setState({
            type
        })
    }
    processName(item){
        return (
            _.get(item, 'entityData.Name')
            || _.get(item, 'entityData.CalendarResource.Name') ? [
                moment(_.get(item, 'entityData.Date')).format('M/DD/YYYY h:mm a'),
                _.get(item, 'entityData.CalendarResource.Name')
            ].join(' ') : null
            || _.get(item, 'entityData.Description')
            || (
                [
                    _.get(item, 'entityData.User.FirstName'),
                    _.get(item, 'entityData.User.LastName')
                ].join(' ')
            )
        )
    }
    processSynced(item){
        let lastTranscribedAt;
        if(item.lastTranscribedAt) {
            lastTranscribedAt = moment(item.lastTranscribedAt)
        }else{
            return 'warning'
        }

        let lastFetchedAt;
        if(item.lastFetchedAt) lastFetchedAt = moment(item.lastFetchedAt)

        if(lastFetchedAt.isSameOrAfter(lastTranscribedAt)) {
            return 'processing'
        }

        return 'success'
    }
    componentWillUnmount(){
        clearInterval(this.syncInteval)
    }
    get evetWorkers(){
        return [
            'Appointment Statuses',
            'Classifications',
            'Breeds',
            'Providers',
            'Genders',
            'Appointment Types',
            'Clients',
            'Patients',
            'Appointments',
            'Schedules',
            'PatientReminders'
        ]
    }
    get rhapsodyWorkers(){
        return [
            'Administrator Types',
            'Appointment Statuses',
            'Appointment Types',
            'Classifications',
            'Breeds',
            'Genders',
            'Providers',
            'Clients',
            'Patients',
            'Appointments'
        ]
    }
    get vetterWorkers(){
        return [
            'Classifications',
            'Breeds',
            'Genders',
            'Providers',
            'Appointment Types',
            'Clients',
            'Patients',
            'Appointments'
        ]
    }
  
    get covetrusWorkers(){
        return [
            'Classifications',
            'Breeds',
            'Genders',
            'Appointment Types',
            'Providers',
            'Clients',
            'Patients',
            'Appointments',
            'Blockoff Resources'
        ]
    }
    get covetrusRepeatingWorkers(){
        return [
            'Generate',
            'Retranscribe'
        ]
    }
    get datapointWorkers(){
        return [
            'Appointment Types',
            'Classifications',
            'Breeds',
            'Genders',
            'Providers',
            'Clients',
            'Patients',
            'Appointments',
        ]
    }
    get datapointArchiverWorkers(){
        return [
            'Appointments'
        ]
    }
    workerName(name){
        if('AdministratorTypesWorker' == name) return 'Administrator Types'
        if('AppointmentStatusesWorker' == name) return 'Appointment Statuses'
        if('AppointmentTypesWorker' == name) return 'Appointment Types'
        if('AppointmentsFetchWorker' == name) return 'Appointments'
        if('RecurringAppointmentsWorker' == name) return 'Recurring Appointments'
        if('ResourceBlockoffsWorker' == name) return 'Resource Blockoffs'
        if('PatientRemindersWorker' == name) return 'Patient Reminders'
        if('RepeatingBlocksGenerateWorker' == name) return 'Generate'
        if('RepeatingBlocksRetranscribeWorker' == name) return 'Retranscribe'

        return `${name}`.replace('Worker', '')
    }
    get evetServerData(){
        return _.get(this, 'props.syncStatus.evetServer') || {}
    }
    get evetFetcherData(){
        return _.get(this, 'props.syncStatus.fetcher') || {}
    }
    get evetTranscriberData(){
        return _.get(this, 'props.syncStatus.transcriber') || {}
    }
    get rhapsodyFetcherData(){
        return _.get(this, 'props.syncStatus.rhapsodyFetcher') || {}
    }
    get rhapsodyTranscriberData(){
        return _.get(this, 'props.syncStatus.rhapsodyTranscriber') || {}
    }
    get vetterFetcherData(){
        return _.get(this, 'props.syncStatus.vetterFetchers') || {}
    }
    get vetterTranscriberData(){
        return _.get(this, 'props.syncStatus.vetterTranscribers') || {}
    }
    get covetrusFetcherData(){
        return _.get(this, 'props.syncStatus.covetrusFetchers') || {}
    }
    get covetrusTranscriberData(){
        return _.get(this, 'props.syncStatus.covetrusTranscribers') || {}
    }
    get covetrusRepeatingData(){
        return _.get(this, 'props.syncStatus.covetrusRepeating') || {}
    }
    get datapointFetcherData(){
        return _.get(this, 'props.syncStatus.datapointFetcher') || {}
    }
    get datapointTranscriberData(){
        return _.get(this, 'props.syncStatus.datapointTranscriber') || {}
    }
    get datapointArchiverData(){
        return _.get(this, 'props.syncStatus.datapointArchiver') || {}
    }
    get availabilitiesData(){
        return _.get(this, 'props.syncStatus.availabilities') || {}
    }
    get remindersData(){
        return _.get(this, 'props.syncStatus.reminders') || {}
    }
    get organizationRemindersData(){
        return _.get(this, 'props.syncStatus.organizationReminders') || {}
    }
    isCurrentFetcherWorker(name){
        return this.workerName(
            _.get(this, 'props.syncStatus.fetcher.current')
        ) == name
    }
    isCurrentSyncWorker(name){
        return this.workerName(
            _.get(this, 'props.syncStatus.sync.current')
        ) == name
    }
    isCurrentTranscriberWorker(name){
        return this.workerName(
            _.get(this, 'props.syncStatus.transcriber.current')
        ) == name
    }
    isCurrentVetterFetcherWorker(name){
        return this.workerName(
            _.get(this, 'props.syncStatus.vetterFetchers.current')
        ) == name
    }
    isCurrentVetterTranscriberWorker(name){
        return this.workerName(
            _.get(this, 'props.syncStatus.vetterTranscribers.current')
        ) == name
    }
    isCurrentCovetrusFetcherWorker(name){
        return this.workerName(
            _.get(this, 'props.syncStatus.covetrusFetchers.current')
        ) == name
    }
    isCurrentCovetrusTranscriberWorker(name){
        return this.workerName(
            _.get(this, 'props.syncStatus.covetrusTranscribers.current')
        ) == name
    }
    isCurrentRhapsodyFetcherWorker(name){
        console.log(name)
        return this.workerName(
            _.get(this, 'props.syncStatus.rhapsodyFetcher.current')
        ) == name
    }
    isCurrentRhapsodyTranscriberWorker(name){
        return this.workerName(
            _.get(this, 'props.syncStatus.rhapsodyTranscriber.current')
        ) == name
    }
    isCurrentCovetrusRepeatingWorker(name){
        return this.workerName(
            _.get(this, 'props.syncStatus.covetrusRepeating.current')
        ) == name
    }
    isCurrentDatapointFetchersWorker(name){
        return this.workerName(
            _.get(this, 'props.syncStatus.datapointFetcher.current')
        ) == name
    }
    isCurrentDatapointTranscribersWorker(name){
        return this.workerName(
            _.get(this, 'props.syncStatus.datapointTranscriber.current')
        ) == name
    }
    isCurrentDatapointArchiverWorker(name){
        return this.workerName(
            _.get(this, 'props.syncStatus.datapointArchiver.current')
        ) == name
    }
    isActive(type){
        return _.get(this, `props.integrationConfigs.${type}.active`) || _.get(this, `props.integrationConfigs.${type}.fetchActive`) || _.get(this, `props.integrationConfigs.${type}.transcribeActive`)
    }
    activeFor(item){
        if(item){
            const diff = moment.duration(moment().diff(item))
            const days = Math.round(diff.asDays())
            const hours = Math.round(diff.asHours())
            const minutes = Math.round(diff.minutes())
            const seconds = Math.round(diff.seconds())
            if(days > 1){
                return `${days} days`
            }
            if(hours > 1){
                return `${hours} hours`
            }
            if(minutes > 1){
                return `${minutes} minutes`
            }
            return `${seconds} seconds`
        }else{
            return false
        }
    }
    render(){
        const { initLoading, loading } = this.state;
        const list = this.data

        const loadMore =
        !initLoading && !loading ? (
          <div
            style={{
              textAlign: 'center',
              marginTop: 12,
              height: 32,
              lineHeight: '32px',
            }}
          >
            <Button onClick={this.onLoadMore}>loading more</Button>
          </div>
        ) : null;


        return (
            <Scroller>
                <Card bordered={false}>
                    <Row>
                        <Col xs={24}>
                            <label>Evet Server Sync</label>
                            <br />
                            {this.activeFor(this.evetServerData.startedAt) ? (
                                <div>
                                    Series Active for about {this.activeFor(this.evetServerData.startedAt)}
                                    <br />
                                    Series last ping was about {this.activeFor(this.evetServerData.ping)} ago
                                </div>
                            ): (
                                <div>
                                    Inactive
                                    <br />
                                    <br />
                                </div>
                            )}
                            <br />
                            <br />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={24}>
                            <label>Availabilities</label>
                            <br />
                            {this.activeFor(this.availabilitiesData.startedAt) ? (
                                <div>
                                    Series Active for about {this.activeFor(this.availabilitiesData.startedAt)}
                                    <br />
                                    Series last ping was about {this.activeFor(this.availabilitiesData.ping)} ago
                                </div>
                            ): (
                                <div>
                                    Inactive
                                    <br />
                                    <br />
                                </div>
                            )}
                            <br />
                            <br />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={24}>
                            <label>Reminders</label>
                            <br />
                            {this.activeFor(this.remindersData.startedAt) ? (
                                <div>
                                    Series Active for about {this.activeFor(this.remindersData.startedAt)}
                                    <br />
                                    Series last ping was about {this.activeFor(this.remindersData.ping)} ago
                                </div>
                            ): (
                                <div>
                                    Inactive
                                    <br />
                                    <br />
                                </div>
                            )}
                            <br />
                            <br />
                        </Col>
                        <Col xs={24}>
                            <label>Organization Reminders</label>
                            <br />
                            {this.activeFor(this.organizationRemindersData.startedAt) ? (
                                <div>
                                    Series Active for about {this.activeFor(this.organizationRemindersData.startedAt)}
                                    <br />
                                    Series last ping was about {this.activeFor(this.organizationRemindersData.ping)} ago
                                </div>
                            ): (
                                <div>
                                    Inactive
                                    <br />
                                    <br />
                                </div>
                            )}
                            <br />
                            <br />
                        </Col>
                    </Row>

                    {this.isActive('evet') && (
                        <Row>
                                <Col xs={12}>
                                        <label>Evet Fetcher</label>
                                        <br />
                                        {this.activeFor(this.evetFetcherData.startedAt) ? (
                                            <div>
                                                Series Active for about {this.activeFor(this.evetFetcherData.startedAt)}
                                                <br />
                                                Series last ping was about {this.activeFor(this.evetFetcherData.ping)} ago
                                                <br />
                                                Series uuid {this.evetFetcherData.id}
                                                <br />
                                                Series started at {moment(this.evetFetcherData.startedAt).format()}
                                            </div>
                                        ): (
                                            <div>
                                                Inactive
                                                <br />
                                                <br />
                                            </div>
                                        )}
                                        {this.evetWorkers.map((row, i)=>(
                                            this.isCurrentFetcherWorker(row) ? (
                                                <div key={`fetcher-${i}`}>
                                                    <label>{this.workerName(row)} {this.evetFetcherData.message}</label>
                                                </div>
                                            ):(
                                                <div key={`fetcher-${i}`}>
                                                    <label style={{color: 'aaa'}}>{this.workerName(row)}</label>
                                                </div>
                                            )
                                        ))}
                                </Col>
                                <Col xs={12}>
                                    <label>Evet Transcriber</label>
                                    <br />
                                    {this.activeFor(this.evetTranscriberData.startedAt) ? (
                                        <div>
                                            Series active for about {this.activeFor(this.evetTranscriberData.startedAt)}
                                            <br />
                                            Series last ping was about {this.activeFor(this.evetTranscriberData.ping)} ago
                                            <br />
                                            Series uuid {this.evetTranscriberData.id}
                                            <br />
                                            Series started at {moment(this.evetTranscriberData.startedAt).format()}
                                        </div>
                                    ): (
                                        <div>
                                            Inactive
                                            <br />
                                            <br />
                                        </div>
                                    )}
                                    {this.evetWorkers.map((row, i)=>(
                                        this.isCurrentTranscriberWorker(row) ? (
                                            <div key={`transcriber-${i}`}>
                                                <label>{this.workerName(row)} {this.evetTranscriberData.message}</label>
                                            </div>
                                        ):(
                                            <div key={`transcriber-${i}`}>
                                                <label style={{color: 'aaa'}}>{this.workerName(row)}</label>
                                            </div>
                                        )
                                        ))}
                                </Col>
                            </Row>
                    )}
                    {this.isActive('rhapsody') && (
                        <Row>
                                <Col xs={12}>
                                        <label>Rhapsody Fetcher</label>
                                        <br />
                                        {this.activeFor(this.rhapsodyFetcherData.startedAt) ? (
                                            <div>
                                                Series Active for about {this.activeFor(this.rhapsodyFetcherData.startedAt)}
                                                <br />
                                                Series last ping was about {this.activeFor(this.rhapsodyFetcherData.ping)} ago
                                                <br />
                                                Series uuid {this.rhapsodyFetcherData.id}
                                                <br />
                                                Series started at {moment(this.rhapsodyFetcherData.startedAt).format()}
                                            </div>
                                        ): (
                                            <div>
                                                Inactive
                                                <br />
                                                <br />
                                            </div>
                                        )}
                                        {this.rhapsodyWorkers.map((row, i)=>(
                                            this.isCurrentRhapsodyFetcherWorker(row) ? (
                                                <div key={`fetcher-${i}`}>
                                                    <label>{this.workerName(row)} {this.rhapsodyFetcherData.message}</label>
                                                </div>
                                            ):(
                                                <div key={`fetcher-${i}`}>
                                                    <label style={{color: 'aaa'}}>{this.workerName(row)}</label>
                                                </div>
                                            )
                                        ))}
                                </Col>
                                <Col xs={12}>
                                    <label>Rhapsody Transcriber</label>
                                    <br />
                                    {this.activeFor(this.rhapsodyTranscriberData.startedAt) ? (
                                        <div>
                                            Series active for about {this.activeFor(this.rhapsodyTranscriberData.startedAt)}
                                            <br />
                                            Series last ping was about {this.activeFor(this.rhapsodyTranscriberData.ping)} ago
                                            <br />
                                            Series uuid {this.rhapsodyTranscriberData.id}
                                            <br />
                                            Series started at {moment(this.rhapsodyTranscriberData.startedAt).format()}
                                        </div>
                                    ): (
                                        <div>
                                            Inactive
                                            <br />
                                            <br />
                                        </div>
                                    )}
                                    {this.rhapsodyWorkers.map((row, i)=>(
                                        this.isCurrentRhapsodyTranscriberWorker(row) ? (
                                            <div key={`transcriber-${i}`}>
                                                <label>{this.workerName(row)} {this.rhapsodyTranscriberData.message}</label>
                                            </div>
                                        ):(
                                            <div key={`transcriber-${i}`}>
                                                <label style={{color: 'aaa'}}>{this.workerName(row)}</label>
                                            </div>
                                        )
                                        ))}
                                </Col>
                            </Row>
                    )}
                    {this.isActive('vetter') && (
                        <Row>
                            <Col xs={12}>
                                    <label>Vetter Fetchers Sync</label>
                                    <br />
                                    {this.activeFor(this.vetterData.startedAt) ? (
                                        <div>
                                            Series Active for about {this.activeFor(this.vetterData.startedAt)}
                                            <br />
                                            Series last ping was about {this.activeFor(this.vetterData.ping)} ago
                                            <br />
                                            Series uuid {this.vetterData.id}
                                            <br />
                                            Series started at {moment(this.vetterData.startedAt).format()}
                                        </div>
                                    ): (
                                        <div>
                                            Inactive
                                            <br />
                                            <br />
                                        </div>
                                    )}
                                    <label>
                                        <i>Daily</i>
                                    </label>
                                    <br />
                                    {this.vetterWorkers.map((row, i)=>(
                                        this.isCurrentVetterFetcherWorker(row) ? (
                                            <div key={`vetter-${i}`}>
                                                <label>{this.workerName(row)} {this.vetterWorkers.message}</label>
                                            </div>
                                        ):(
                                            <div key={`vetter-${i}`}>
                                                <label style={{color: 'aaa'}}>{this.workerName(row)}</label>
                                            </div>
                                        )
                                    ))}
                                    <br />
                                    <label>
                                        <i>Normal</i>
                                    </label>
                                    <br />
                                    {this.vetterNormalWorkers.map((row, i)=>(
                                        this.isCurrentVetterTranscriberWorker(row) ? (
                                            <div key={`vetter-${i}`}>
                                                <label>{this.workerName(row)} {this.vetterTranscriberData.message}</label>
                                            </div>
                                        ):(
                                            <div key={`vetter-${i}`}>
                                                <label style={{color: 'aaa'}}>{this.workerName(row)}</label>
                                            </div>
                                        )
                                    ))}
                            </Col>
                            <Col xs={12}>
                                    <label>Vetter Transcrribers Sync</label>
                                    <br />
                                    {this.activeFor(this.vetterData.startedAt) ? (
                                        <div>
                                            Series Active for about {this.activeFor(this.vetterData.startedAt)}
                                            <br />
                                            Series last ping was about {this.activeFor(this.vetterData.ping)} ago
                                            <br />
                                            Series uuid {this.vetterData.id}
                                            <br />
                                            Series started at {moment(this.vetterData.startedAt).format()}
                                        </div>
                                    ): (
                                        <div>
                                            Inactive
                                            <br />
                                            <br />
                                        </div>
                                    )}
                                    <label>
                                        <i>Daily</i>
                                    </label>
                                    <br />
                                    {this.vetterDailyWorkers.map((row, i)=>(
                                        this.isCurrentVetterWorker(row) ? (
                                            <div key={`vetter-${i}`}>
                                                <label>{this.workerName(row)} {this.evetTranscriberData.message}</label>
                                            </div>
                                        ):(
                                            <div key={`vetter-${i}`}>
                                                <label style={{color: 'aaa'}}>{this.workerName(row)}</label>
                                            </div>
                                        )
                                    ))}
                                    <br />
                                    <label>
                                        <i>Normal</i>
                                    </label>
                                    <br />
                                    {this.vetterNormalWorkers.map((row, i)=>(
                                        this.isCurrentVetterWorker(row) ? (
                                            <div key={`vetter-${i}`}>
                                                <label>{this.workerName(row)} {this.evetTranscriberData.message}</label>
                                            </div>
                                        ):(
                                            <div key={`vetter-${i}`}>
                                                <label style={{color: 'aaa'}}>{this.workerName(row)}</label>
                                            </div>
                                        )
                                    ))}
                            </Col>
                        </Row>
                    )}
                    {this.isActive('covetrus') && (
                        <Row>
                            <Col xs={12}>
                                    <label>Covetrus Fetchers</label>
                                    <br />
                                    {this.activeFor(this.covetrusFetcherData.startedAt) ? (
                                        <div>
                                            Series Active for about {this.activeFor(this.covetrusFetcherData.startedAt)}
                                            <br />
                                            Series last ping was about {this.activeFor(this.covetrusFetcherData.ping)} ago
                                            <br />
                                            Series uuid {this.covetrusFetcherData.id}
                                            <br />
                                            Series started at {moment(this.covetrusFetcherData.startedAt).format()}
                                        </div>
                                    ): (
                                        <div>
                                            Inactive
                                            <br />
                                            <br />
                                        </div>
                                    )}
                                    {this.covetrusWorkers.map((row, i)=>(
                                        this.isCurrentCovetrusFetcherWorker(row) ? (
                                            <div key={`covetrus-${i}`}>
                                                <label>{this.workerName(row)} {this.covetrusFetcherData.message}</label>
                                            </div>
                                        ):(
                                            <div key={`covetrus-${i}`}>
                                                <label style={{color: 'aaa'}}>{this.workerName(row)}</label>
                                            </div>
                                        )
                                    ))}
                                    <br />
                                    <label>Current: {_.get(this, 'props.syncStatus.covetrusFetchers.current')}</label>
                            </Col>
                            <Col xs={12}>
                                    <label>Covetrus Transcribers</label>
                                    <br />
                                    {this.activeFor(this.covetrusTranscriberData.startedAt) ? (
                                        <div>
                                            Series Active for about {this.activeFor(this.covetrusTranscriberData.startedAt)}
                                            <br />
                                            Series last ping was about {this.activeFor(this.covetrusTranscriberData.ping)} ago
                                            <br />
                                            Series uuid {this.covetrusTranscriberData.id}
                                            <br />
                                            Series started at {moment(this.covetrusTranscriberData.startedAt).format()}
                                        </div>
                                    ): (
                                        <div>
                                            Inactive
                                            <br />
                                            <br />
                                        </div>
                                    )}
                                    {this.covetrusWorkers.map((row, i)=>(
                                        this.isCurrentCovetrusTranscriberWorker(row) ? (
                                            <div key={`covetrus-${i}`}>
                                                <label>{this.workerName(row)} {this.covetrusTranscriberData.message}</label>
                                            </div>
                                        ):(
                                            <div key={`covetrus-${i}`}>
                                                <label style={{color: 'aaa'}}>{this.workerName(row)}</label>
                                            </div>
                                        )
                                    ))}
                                    <br />
                                    <label>Current: {_.get(this, 'props.syncStatus.covetrusTranscribers.current')}</label>
                            </Col>
                        </Row>
                    )}

                    <br />
                    <br />
                    <br />

                    <ReactJson src={this.props.syncStatus} />
                    <Row>
                    <br />
                </Row>
                        {this.isActive('datapoint') && (
                            <div>
                                <label>Datapoint</label>
                                <Row>
                                    <Col xs={8}>
                                        <label>Fetchers</label>
                                        <br />
                                        {this.activeFor(this.datapointFetcherData.startedAt) ? (
                                            <div>
                                                Series Active for about {this.activeFor(this.datapointFetcherData.startedAt)}
                                                <br />
                                                Series last ping was about {this.activeFor(this.datapointFetcherData.ping)} ago
                                                <br />
                                                Series uuid {this.datapointFetcherData.id}
                                                <br />
                                                Series started at {moment(this.datapointFetcherData.startedAt).format()}
                                            </div>
                                        ): (
                                            <div>
                                                Inactive
                                                <br />
                                                <br />
                                            </div>
                                        )}
                                        {this.datapointWorkers.map((row, i)=>(
                                            this.isCurrentDatapointFetchersWorker(row) ? (
                                                <div key={`datapoint-${i}`}>
                                                    <label>{this.workerName(row)} {this.datapointFetcherData.message}</label>
                                                </div>
                                            ):(
                                                <div key={`datapoint-${i}`}>
                                                    <label style={{color: 'aaa'}}>{this.workerName(row)}</label>
                                                </div>
                                            )
                                        ))}
                                    </Col>
                                    <Col xs={8}>
                                        <label>Transcribers</label>
                                        <br />
                                        {this.activeFor(this.datapointTranscriberData.startedAt) ? (
                                            <div>
                                                Series Active for about {this.activeFor(this.datapointTranscriberData.startedAt)}
                                                <br />
                                                Series last ping was about {this.activeFor(this.datapointTranscriberData.ping)} ago
                                                <br />
                                                Series uuid {this.datapointTranscriberData.id}
                                                <br />
                                                Series started at {moment(this.datapointTranscriberData.startedAt).format()}
                                            </div>
                                        ): (
                                            <div>
                                                Inactive
                                                <br />
                                                <br />
                                            </div>
                                        )}
                                        {this.datapointWorkers.map((row, i)=>(
                                            this.isCurrentDatapointTranscribersWorker(row) ? (
                                                <div key={`datapoint-${i}`}>
                                                    <label>{this.workerName(row)} {this.datapointTranscriberData.message}</label>
                                                </div>
                                            ):(
                                                <div key={`datapoint-${i}`}>
                                                    <label style={{color: 'aaa'}}>{this.workerName(row)}</label>
                                                </div>
                                            )
                                        ))}
                                    </Col>
                                    <Col xs={8}>
                                        <label>Archivers</label>
                                        <br />
                                        {this.activeFor(this.datapointArchiverData.startedAt) ? (
                                            <div>
                                                Series Active for about {this.activeFor(this.datapointArchiverData.startedAt)}
                                                <br />
                                                Series last ping was about {this.activeFor(this.datapointArchiverData.ping)} ago
                                                <br />
                                                Series uuid {this.datapointArchiverData.id}
                                                <br />
                                                Series started at {moment(this.datapointArchiverData.startedAt).format()}
                                            </div>
                                        ): (
                                            <div>
                                                Inactive
                                                <br />
                                                <br />
                                            </div>
                                        )}
                                        {this.datapointArchiverWorkers.map((row, i)=>(
                                            this.isCurrentDatapointArchiverWorker(row) ? (
                                                <div key={`datapoint-${i}`}>
                                                    <label>{this.workerName(row)} {this.datapointArchiverData.message}</label>
                                                </div>
                                            ):(
                                                <div key={`datapoint-${i}`}>
                                                    <label style={{color: 'aaa'}}>{this.workerName(row)}</label>
                                                </div>
                                            )
                                        ))}
                                    </Col>
                                </Row>
                            </div>
                        )}
                        <br />
                        <br />
                </Card>
            </Scroller>
        )
    }
}

export default connect((props)=>({
    data: props.sync,
    syncStatus: props.syncStatus,
    integrationConfigs: props.integrationConfigs
}))(Fetch)
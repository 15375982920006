import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { Row, Col, Timeline, Card, Input } from 'antd'
import { ClockCircleOutlined,CaretDownFilled, CaretUpFilled } from '@ant-design/icons'
import {Fa, AdminLayout, Charts, Scroller, Money} from 'components/elements'
import SectionContent from 'components/elements/SectionContent'
import {accountActions, insightsActions} from 'actions'
import moment from 'moment'
import {NotesWidget} from 'components/partials'
import _ from 'lodash'
import ClientCounts from './ClientCounts'
import Dropoffs from './Dropoffs'

class Dashboard extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            total: 120,
            cost: 60
        }
        this.toggleBreakDown = this.toggleBreakDown.bind(this)
        this.handleCost = this.handleCost.bind(this)
        this.handleTotal = this.handleTotal.bind(this)
        this.administratorData = this.administratorData.bind(this)
    }
  componentDidMount(){
    insightsActions.fetch()
  }
  get totalReturning(){
    return _.get(this, 'props.insights.appointments.createdByUser.returning', []).reduce((acc, {total})=>(
        acc + parseInt(total)
    ), 0)
  }

  get totalNew(){
    return _.get(this, 'props.insights.appointments.createdByUser.new', []).reduce((acc, {total})=>(
        acc + parseInt(total)
    ), 0)
  }
  toggleBreakDown(type,e){
    e.preventDefault()
    this.setState({
        [type]: !this.state[type]
    })
  }
  previousWeek(target, weeksBack = 1){
    const rootDate = moment().startOf('day').endOf('week').subtract(weeksBack, 'week')
    return target.filter(({date})=>{
        return moment(date, 'YY-MM-DD').isBetween(rootDate.clone(), rootDate.clone().add(1, 'week'))
    }).reduce((acc, {total})=>(
        acc + parseInt(total)
    ), 0)
  }
  previousWeeksComparison(target, weekBack = 1){
    const week1 = this.previousWeek(target, weekBack)
    const week2 = this.previousWeek(target, weekBack + 1)
    const topPercent = week1 > week2 ? week1 : week2
    const difference = week1 - week2

    let value = 0
    if(topPercent !== 0){
        if(week2 == 0){
           value = -100
        }else{
            value = Math.round(((difference / topPercent) * 100) || 0)
        }
    }

    if(weekBack == 1){
        const days = moment.duration(moment().diff(moment().endOf('week'))).asDays()
        const diff = 7 - days
        
        value = (value / diff) * 7
    }

    console.log(value)
    if(100 - value !== 100){
        return Math.round(value)
    }else{
        return 0
    }
  }
  getPercentOf(item1, item2){
    return Math.round((item1 / (item1 + item2)) * 100);
  }
  handleTotal(e){
    this.setState({
        total: e.target.value,
    })
  }
  handleCost(e){
    this.setState({
        cost: e.target.value,
    })
  }
  getValueBasicReturn(total){
    const diff = this.state.total - this.state.cost

    return total * diff
  }

    filterByCreatorTypeData(data, creatorType, optional = {}){
        return Object.entries(data).reduce((acc, [key, row])=>({
            ...acc,
            [key]: {
                ...row,
                ...optional,
                days: row.days.filter((day)=>{
                    return day.creatorType == creatorType
                })
            }
        }), {})
    }

    administratorData(datasets, labels, backgroundColors=[]){
        const v = {
            datasets: datasets.map((data, i)=>{
                let optional = {}
                if(backgroundColors[i]) optional.backgroundColor = backgroundColors[i]

                return {
                    label: labels[i],
                    ...optional,
                    data: Object.keys(data).sort((integrationIDa, integrationIDb)=>{
                        const {days: daysA} = data[integrationIDa]
                        const {days: daysB} = data[integrationIDb]
                        return this.previousWeek(daysA, 2) < this.previousWeek(daysB, 2) ? 1 : -1
                    }).map((integrationID)=>{
                        const {administrator, days} = data[integrationID]
                        return ({
                            y: this.previousWeek(days, 2),
                            x: `${administrator.firstName || ""} ${administrator.lastName || ""}`
                        })
                    })
                }
            }).filter(({data})=>{
                return data.length != 0
            })
        }
        console.log(v);
        return v
    }
    getAdjustedPercentage(value){

    }
    render() {
        const borderColor = '#eaeaea'
        const overlayPanel = {position: 'absolute', zIndex: 100, left: '0px', right: '0px', backgroundColor: '#fff', padding: '1em 1em 0em 1em', border: `1px solid ${borderColor}`, borderRadius: '4px'}
        const newClients = _.get(this, 'props.insights.appointments.createdByUser.new', [])
        const returningClients = _.get(this, 'props.insights.appointments.createdByUser.returning', [])
        const allOnlineClients = newClients.concat(returningClients);
        const allInHouseBookings = _.get(this, 'props.insights.appointments.createdForAdministratorInHouse', [])
        const allBookings = allOnlineClients.concat(allInHouseBookings);
        const administrators = _.get(this, 'props.insights.appointments.administrators', {})

        const clientCountsByZip = _.get(this, 'props.insights.clientCounts', [])

        const mainTitle = { padding: 12, display: 'block', color: '#26292c', textAlign: 'center', fontSize: '1.8em', fontWeight: 'bold'}

        const portion = Math.round(moment.duration(moment().diff(moment().startOf('week'))).asDays() / 7)

        return (
            <AdminLayout>
                <SectionContent title="Dashboard" noPadding={true} noMargin={true}>
                <Scroller>
                    <div style={{backgroundColor: '#e2e7ee'}}>
                        <Fragment>
                            <Row style={{fontSize: '1em', paddingBottom: '1em'}}>
                                <Col xs={24} xxl={12}>
                                    <div style={mainTitle}>
                                        Online
                                    </div>
                                    <Row gutter={5} style={{margin: '0px 4px 4px'}}>
                                        <Col xs={12}>
                                            <Card title="New Client Bookings" style={{border: '0px', fontSize: '1em'}} headStyle={{background: "#229543", color: '#fff', fontSize: '.9em'}} bodyStyle={{minHeight: 200, fontSize: '1em'}}>
                                                <Row>
                                                    <Col xs={24} lg={8}>
                                                        <div  style={{textAlign:'center'}}>
                                                            <label>
                                                                Last 3 months
                                                            </label>
                                                            <br />
                                                            <div style={{textAlign: 'center'}}>
                                                                <span style={{fontSize: '2em'}}>
                                                                    {_.get(this, 'props.insights.appointments.createdByUser.new', []).reduce((acc, {total})=>(
                                                                        acc + parseInt(total)
                                                                    ), 0).toLocaleString("en-US")}
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <br />
                                                    </Col>
                                                    <Col xs={24} lg={16}>
                                                        <Row>
                                                            <Col xs={24} lg={12}>
                                                                <div  style={{textAlign:'center'}}>
                                                                    <label>
                                                                        Last Week
                                                                    </label>
                                                                    <br />
                                                                    <div style={{textAlign: 'center', }}>
                                                                        <span style={{fontSize: '2em'}}>
                                                                            {this.previousWeek(newClients, 2).toLocaleString("en-US")}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                            <Col xs={24} lg={12}>
                                                                <div  style={{textAlign:'center'}}>
                                                                    <label>
                                                                        This Week
                                                                    </label>
                                                                    <br />
                                                                    <span style={{fontSize: '2em'}}>
                                                                        {this.previousWeek(newClients, 1).toLocaleString("en-US")}
                                                                    </span>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        <br />
                                                        Projected to be <br />{this.previousWeeksComparison(newClients, 1) >= 0 ? (
                                                            <Fragment>
                                                                Up <span style={{color: '#23bd01'}}><CaretUpFilled /> {this.previousWeeksComparison(newClients, 1)}%</span>
                                                            </Fragment>
                                                        ) : (
                                                            <Fragment>
                                                                Down <span style={{color: '#af0000'}}><CaretDownFilled /> {this.previousWeeksComparison(newClients, 1)}%</span>
                                                            </Fragment>
                                                        )} from last week
                                                    </Col>
                                                </Row>
                                                <br />
                                                <a onClick={this.toggleBreakDown.bind(null, 'newBookedDetails')}>
                                                    <Fa icon='list-ul' />
                                                </a>
                                                {this.state.newBookedDetails && (
                                                    <div style={overlayPanel}>
                                                        <Timeline>
                                                            {_.get(this, 'props.insights.appointments.createdByUser.new', []).map(({date, total})=>(
                                                                <Timeline.Item color={total != 0 ? 'green':'red'} dot={<ClockCircleOutlined />}>
                                                                        <h2>{total}</h2>
                                                                        <h3>{moment(date, 'YY-MM-DD').format('M-DD-YYYY')}</h3>
                                                                </Timeline.Item>
                                                            ))}
                                                        </Timeline>
                                                    </div>
                                                )}
                                            </Card>
                                        </Col>
                                        <Col xs={12}>
                                            <Card title="Returning Client Bookings" style={{border: '0px', fontSize: '1em'}} headStyle={{background: "#006fa3", color: '#fff', fontSize: '.9em'}} bodyStyle={{minHeight: 200, fontSize: '1em'}}>
                                                <Row>
                                                    <Col xs={24} lg={8}>
                                                        <div style={{textAlign: 'center'}}>
                                                            <label>
                                                                Last 3 months
                                                            </label>
                                                            <br />
                                                            <span style={{fontSize: '1.8em'}}>
                                                                {_.get(this, 'props.insights.appointments.createdByUser.returning', []).reduce((acc, {total})=>(
                                                                    acc + parseInt(total)
                                                                ), 0).toLocaleString("en-US")}
                                                            </span>
                                                        </div>
                                                    </Col>
                                                    <Col xs={24} lg={16}>
                                                        <Row>
                                                            <Col xs={24} lg={12}>
                                                                <div  style={{textAlign:'center'}}>
                                                                    <label>
                                                                        Last Week
                                                                    </label>
                                                                    <br />
                                                                    <span style={{fontSize: '2em'}}>
                                                                        {this.previousWeek(returningClients, 2).toLocaleString("en-US")}
                                                                    </span>
                                                                </div>
                                                            </Col>
                                                            <Col xs={24} lg={12}>
                                                                <div  style={{textAlign:'center'}}>
                                                                    <label>
                                                                        This Week
                                                                    </label>
                                                                    <br />
                                                                    <span style={{fontSize: '2em'}}>
                                                                        {this.previousWeek(returningClients, 1).toLocaleString("en-US")}
                                                                    </span>

                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        <br />
                                                        Projected to be <br />{this.previousWeeksComparison(returningClients, 1) >= 0 ? (
                                                            <Fragment>
                                                                Up <span style={{color: '#23bd01'}}><CaretUpFilled /> {this.previousWeeksComparison(returningClients, 1)}%</span>
                                                            </Fragment>
                                                        ) : (
                                                            <Fragment>
                                                                Down <span style={{color: '#af0000'}}><CaretDownFilled /> {this.previousWeeksComparison(returningClients, 1)}%</span>
                                                            </Fragment>
                                                        )} from last week
                                                    </Col>
                                                </Row>
                                                <br />
                                                <a onClick={this.toggleBreakDown.bind(null, 'returningBookedDetails')}>
                                                    <Fa icon='list-ul' />
                                                </a>
                                                {this.state.returningBookedDetails && (
                                                    <div style={overlayPanel}>
                                                        <Timeline>
                                                            {_.get(this, 'props.insights.appointments.createdByUser.returning', []).map(({date, total})=>(
                                                                <Timeline.Item color={total != 0 ? 'green':'red'} dot={<ClockCircleOutlined />}>
                                                                        <h2>{total}</h2>
                                                                        <h3>{moment(date, 'YY-MM-DD').format('M-DD-YYYY')}</h3>
                                                                </Timeline.Item>
                                                            ))}
                                                        </Timeline>
                                                    </div>
                                                )}
                                            </Card>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col xs={24} xxl={0}>
                                    <br />
                                    <br />
                                    <br />
                                </Col>
                                <Col xs={12} xxl={6}>
                                    <div style={mainTitle}>
                                        In House
                                    </div>
                                    <Row gutter={5} style={{margin: '0px 4px 4px'}}>
                                        <Col xs={24}>
                                            <Card title="CSR Bookings" style={{border: '1px solid #dfdfdf', fontSize: '1em'}} headStyle={{background: "#944263", color: '#fff', fontSize: '.9em'}} bodyStyle={{minHeight: 200, fontSize: '1em'}}>
                                                <Row>
                                                    <Col xs={24} lg={8}>
                                                        <div  style={{textAlign:'center'}}>
                                                            <label>
                                                                Last 3 months
                                                            </label>
                                                            <br />
                                                            <div style={{textAlign: 'center', }}>
                                                                <span style={{fontSize: '2em'}}>
                                                                    {allInHouseBookings.reduce((acc, {total})=>(
                                                                        acc + parseInt(total)
                                                                    ), 0).toLocaleString("en-US")}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                    <Col xs={24} lg={16}>
                                                        <Row>
                                                            <Col xs={24} lg={12}>
                                                                <div  style={{textAlign:'center'}}>
                                                                    <label>
                                                                        Last Week
                                                                    </label>
                                                                    <br />
                                                                    <div style={{textAlign: 'center', }}>
                                                                        <span style={{fontSize: '2em'}}>
                                                                            {this.previousWeek(allInHouseBookings, 2).toLocaleString("en-US")}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                            <Col xs={24} lg={12}>
                                                                <div  style={{textAlign:'center'}}>
                                                                    <label>
                                                                        This Week
                                                                    </label>
                                                                    <br />
                                                                    <div style={{textAlign: 'center', }}>
                                                                        <span style={{fontSize: '2em'}}>
                                                                            {this.previousWeek(allInHouseBookings, 1).toLocaleString("en-US")}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        <br />
                                                        Projected to be <br />{this.previousWeeksComparison(allInHouseBookings, 1) >= 0 ? (
                                                            <Fragment>
                                                                Up <span style={{color: '#23bd01'}}><CaretUpFilled /> {this.previousWeeksComparison(allInHouseBookings, 1)}%</span>
                                                            </Fragment>

                                                        ) : (
                                                            <Fragment>
                                                                Down <span style={{color: '#af0000'}}><CaretDownFilled /> {this.previousWeeksComparison(allInHouseBookings, 1)}%</span>
                                                            </Fragment>
                                                        )} from last week
                                                    </Col>
                                                </Row>
                                                <br />
                                                <a onClick={this.toggleBreakDown.bind(null, 'newBookedDetails')}>
                                                    <Fa icon='list-ul' />
                                                </a>
                                                {this.state.allInHouseClientsBookedDetails && (
                                                    <div style={overlayPanel}>
                                                        <Timeline>
                                                            {allInHouseBookings.map(({date, total})=>(
                                                                <Timeline.Item color={total != 0 ? 'green':'red'} dot={<ClockCircleOutlined />}>
                                                                        <h2>{total}</h2>
                                                                        <h3>{moment(date, 'YY-MM-DD').format('M-DD-YYYY')}</h3>
                                                                </Timeline.Item>
                                                            ))}
                                                        </Timeline>
                                                    </div>
                                                )}
                                            </Card>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col xs={12} xxl={6}>
                                    <div style={mainTitle}>
                                        Total
                                    </div>
                                    <Row gutter={5} style={{margin: '0px 4px 4px'}}>
                                        <Col xs={24}>
                                            <Card title="Online & In House Bookings" style={{border: '1px solid #dfdfdf', fontSize: '1em'}} headStyle={{background: "#496876", color: '#fff', fontSize: '.9em'}} bodyStyle={{minHeight: 200, fontSize: '1em'}}>
                                                <Row>
                                                    <Col xs={24} lg={8}>
                                                        <div  style={{textAlign:'center'}}>
                                                            <label>
                                                                Last 3 months
                                                            </label>
                                                            <div style={{textAlign: 'center', }}>
                                                                <span style={{fontSize: '2em'}}>
                                                                    {allBookings.reduce((acc, {total})=>(
                                                                        acc + parseInt(total)
                                                                    ), 0).toLocaleString("en-US")}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                    <Col xs={24} lg={16}>
                                                        <Row>
                                                            <Col xs={24} lg={12}>
                                                                <div  style={{textAlign:'center'}}>
                                                                    <label>
                                                                        Last Week
                                                                    </label>
                                                                    <br />
                                                                    <div style={{textAlign: 'center', }}>
                                                                        <span style={{fontSize: '2em'}}>
                                                                            {this.previousWeek(allBookings, 2).toLocaleString("en-US")}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                            <Col xs={24} lg={12}>
                                                                <div  style={{textAlign:'center'}}>
                                                                    <label>
                                                                        This Week
                                                                    </label>
                                                                    <br />
                                                                    <div style={{textAlign: 'center', }}>
                                                                        <span style={{fontSize: '2em'}}>
                                                                            {this.previousWeek(allBookings, 1).toLocaleString("en-US")}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        <br />
                                                        Projected to be <br />{(this.previousWeeksComparison(allBookings, 1) * portion) >= 0 ? (
                                                            <Fragment>
                                                                Up <span style={{color: '#23bd01'}}><CaretUpFilled /> {this.previousWeeksComparison(allBookings, 1) * portion}%</span>
                                                            </Fragment>

                                                        ) : (
                                                            <Fragment>
                                                                Down <span style={{color: '#af0000'}}><CaretDownFilled /> {this.previousWeeksComparison(allBookings, 1) * portion}%</span>
                                                            </Fragment>
                                                        )} from last week
                                                    </Col>
                                                </Row>
                                                <br />
                                                <a onClick={this.toggleBreakDown.bind(null, 'newBookedDetails')}>
                                                    <Fa icon='list-ul' />
                                                </a>
                                                {this.state.allOnlineClientsBookedDetails && (
                                                    <div style={overlayPanel}>
                                                        <Timeline>
                                                            {allBookings.map(({date, total})=>(
                                                                <Timeline.Item color={total != 0 ? 'green':'red'} dot={<ClockCircleOutlined />}>
                                                                        <h2>{total}</h2>
                                                                        <h3>{moment(date, 'YY-MM-DD').format('M-DD-YYYY')}</h3>
                                                                </Timeline.Item>
                                                            ))}
                                                        </Timeline>
                                                    </div>
                                                )}
                                            </Card>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row style={{paddingBottom: '1em', marginTop: '3em'}}>
                                <Col xs={24}>
                                    <Card style={{border: '1px solid #dfdfdf'}} bodyStyle={{background: "#dbe4ef", color: '#3b4148', minHeight: 200, boxShadow: '0px 0px 8px #7a9fd0', padding: '10px'}} >
                                        <div style={{border: '3px solid #c5d4e7', padding: '10px 20px', padding: 'calc(100vw / 28) calc(100vw / 40)'}}>
                                            <h1 style={{fontSize: '2em', color: '#3b4148'}}>
                                                Estimated Profit On Investment (Online Bookings)
                                            </h1>
                                            <br />
                                            <br />
                                            <Row style={{fontSize: '1.5em', margin: '1em 0px'}}>
                                                <Col xs={5}>
                                                    Last 3 months
                                                    <h2 style={{fontSize: '1.5em', color: '#083974' }}>
                                                        <Money>
                                                            {this.getValueBasicReturn(
                                                                allOnlineClients.reduce((acc, {total})=>(
                                                                    acc + parseInt(total)
                                                                ), 0)
                                                            ) - 597}
                                                        </Money>
                                                    </h2>
                                                </Col>
                                                <Col xs={5}>
                                                    Last Week
                                                    <h2 style={{fontSize: '1.5em', color: '#083974' }}>
                                                        <Money>
                                                            {this.getValueBasicReturn(
                                                                this.previousWeek(allOnlineClients, 2)
                                                            ) - 49.99}
                                                        </Money>
                                                    </h2>
                                                </Col>
                                                <Col xs={5}>
                                                    This Week
                                                    <h2 style={{fontSize: '1.5em', color: '#083974' }}>
                                                        <Money>
                                                            {this.getValueBasicReturn(
                                                                this.previousWeek(allOnlineClients, 1)
                                                            ) - 49.99}
                                                        </Money>
                                                    </h2>
                                                </Col>
                                                <Col xs={9}>
                                                    Last 3 months (Administrative Time Saved)
                                                    <h2 style={{fontSize: '1.5em', color: '#083974' }}>

                                                        {Math.round((allOnlineClients.reduce((acc, {total})=>(
                                                                acc + parseInt(total)
                                                        ), 0) * 5) / 60)} Hours
                                                    </h2>
                                                </Col>
                                            </Row>
                                            <Row style={{marginBottom: '2em'}}>
                                                <Col xs={12}>
                                                    <Row gutter={5}>
                                                        <Col xs={12}>
                                                            <label>Average Cost to Provide Service(s):</label>
                                                            <Input value={this.state.cost} onChange={this.handleCost} />
                                                        </Col>
                                                        <Col xs={12}>
                                                            <label>Average Total Invoice for Service(s) Provided:</label>
                                                            <Input value={this.state.total} onChange={this.handleTotal} />
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </div>
                                    </Card>
                                </Col>
                            </Row>
                            <Row gutter={13} style={{margin: '0px 4px 4px', marginTop: '3em'}}>
                                <Col xs={10} style={{maxWidth: 652}}>
                                    <Dropoffs />
                                </Col>
                            </Row>
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <hr style={{borderColor: '#efefef'}} />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <Row gutter={13} style={{margin: '0px 4px 4px', marginTop: '3em'}}>
                                <Col xs={12}>
                                    <div style={mainTitle}>
                                        Online
                                    </div>
                                    <Card title="New vs. Returning (Last Week)" style={{border: '1px solid #dfdfdf'}} headStyle={{background: "#624d70", color: '#fff'}} bodyStyle={{minHeight: 200}}>
                                        <Charts.Bar data={
                                            this.administratorData([
                                                this.filterByCreatorTypeData(
                                                    _.get(this, 'props.insights.appointments.administrators', {}), 
                                                    'self'
                                                ),
                                                this.filterByCreatorTypeData(
                                                    _.get(this, 'props.insights.appointments.administrators', {})
                                                , 'system'),
                                                // administrators,
                                            ],
                                                ["New", "Returning"], ['#229543', '#006fa3']
                                            )}
                                            // options={{plugins: { legend: { display: false }}}}
                                        />
                                    </Card>
                                </Col>
                                <Col xs={12}>
                                    <div style={mainTitle}>
                                        Online, In House, and Total
                                    </div>
                                    <Card title="Online vs. In House Bookings (Last Week)" style={{border: '1px solid #dfdfdf'}} headStyle={{background: "#624d70", color: '#fff'}} bodyStyle={{minHeight: 200}}>
                                        <Charts.Bar
                                            data={this.administratorData([
                                                _.get(this, 'props.insights.appointments.administrators', {}),
                                                _.get(this, 'props.insights.appointments.administratorsInHouse', {})
                                            ], ["Online","In House"],["#337b8d", "#944263"])}
                                            // options={{plugins: { legend: { display: false }}}}
                                        />
                                    </Card>
                                </Col>
                            </Row>
                            <Row gutter={13} style={{margin: '0px 4px 4px', marginTop: '3em'}}>
                                <Col xs={12}>
                                    <div style={mainTitle}>
                                        Online
                                    </div>
                                    <Card title="Comparison of New Vs. Returning (Last 3 months)" style={{border: '1px solid #dfdfdf'}} headStyle={{background: "#624d70", color: '#fff'}}>
                                        {(this.totalNew || this.totalReturning) ? (
                                            <Charts.Pie data={{
                                                labels: [
                                                    `${this.getPercentOf(this.totalNew, this.totalReturning)}% New Clients`,
                                                    `${this.getPercentOf(this.totalReturning, this.totalNew)}% Returning Clients`
                                                ],
                                                datasets: [{
                                                    data: [this.totalNew, this.totalReturning],
                                                    backgroundColor: [
                                                        '#229543', '#006fa3'
                                                    ],
                                                    hoverOffset: 4
                                                }]
                                            }} />
                                        ):(
                                            <div>
                                                Not Enough Information Yet
                                            </div>
                                        )}
                                    </Card>
                                </Col>
                            </Row>
                            {/* <Row>
                                <Col xs={24}>
                                    <ClientCounts clientCountsByZip={clientCountsByZip} />
                                </Col>
                            </Row> */}
                           
                        </Fragment>
                        <br />
                        <br />
                    </div>
                </Scroller>
                </SectionContent>
            </AdminLayout>
        )
    }
}

export default connect((state)=>({insights: state.insights}))(Dashboard)

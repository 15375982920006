import {
  LOGIN_ACCOUNT,
  LOGOUT_ACCOUNT,
  CREATE_ORGANIZATION,
  CREATE_USER,
  FETCH_CURRENT_ACCOUNT
} from '../constants/actionTypes'

export default (state = {}, action) => {
  switch (action.type) {
    case CREATE_ORGANIZATION:
      return {
        ...state,
        organizations: [...state.organizations, action.value]
      }
    case CREATE_USER:
      return {
        ...state,
        patients: [...state.patients, action.value]
      }
    case LOGIN_ACCOUNT:
      const values = {
        ...state,
        tempAccessToken: action.tempAccessToken,
        accessToken: action.accessToken,
        patients: action.patients,
        account: action.account,
        organizations: action.organizations,
        accountID: action.accountID
      }
      
      return values;
    case FETCH_CURRENT_ACCOUNT:
      return {
        ...state,
        account: action.payload
      }
    case LOGOUT_ACCOUNT:
      return {
        accessToken: null,
        patients: null,
        acconutID: null,
      }
    default:
      return state
  }
}


import React from 'react';
import PropTypes from 'prop-types';
import Charts from '../../../elements/Charts/index.jsx';
import compileData from './compileData';
import { Card, Col, DatePicker, Row, Table } from 'antd';
import useAxios from '../../../../lib/axioshook';
import requests from '../../../../requests';
import { useSelector } from 'react-redux';
import moment from 'moment';

interface FormCompletion {
    filters: number;
    timeslots: number;
    collectInformation: number;
    confirmation: number;
    total: number;
}

const Dropoffs = ()=>{
    const [state, setState] = React.useState({
        from: moment().subtract(1, 'week').toISOString(),
        to: moment().toISOString()
    })

    const currentOrganization = useSelector((state: any) => state.currentOrganization);

    const {data} = useAxios<FormCompletion>(
        requests.organizations.formCompletions(currentOrganization?.id, state.from, state.to)
    );

    const fromOnChange = (date: any, dateString: string)=>{
        setState({
            from: dateString,
            to: state.to
        })
    }

    const toOnChange = (date: any, dateString: string)=>{
        setState({
            from: state.from,
            to: dateString
        })
    }
    return (
        <div>
            <Card title="Dropoff Rates" style={{border: '1px solid #dfdfdf'}} headStyle={{background: "#624d70", color: '#fff'}} bodyStyle={{minHeight: 200}}>
                {/* <Charts.Bar
                    data={compileData({
                        rows: []
                    })}
                    // options={{plugins: { legend: { display: false }}}}
                /> */}
                <Row gutter={20} style={{marginLeft: 20}}>
                    <Col>
                        <label>From</label> &nbsp;<DatePicker onChange={fromOnChange} defaultValue={moment(state.from)}/> 
                    </Col>
                    <Col>
                        <label>To</label> &nbsp;<DatePicker onChange={toOnChange} defaultValue={moment(state.to)}/>
                    </Col>
                </Row>
                <br />
                <br />
                <Table 
                    showHeader={false}
                    dataSource={
                        [
                            {
                                key: '1',
                                name: 'Filters',
                                value: data?.filters
                            },
                            {
                                key: '2',
                                name: 'Timeslots',
                                value: data?.timeslots
                            },
                            {
                                key: '3',
                                name: 'Client Patient Details',
                                value: data?.collectInformation
                            },
                            {
                                key: '4',
                                name: 'Confirmation',
                                value: data?.confirmation
                            },
                            {
                                key: '5',
                                name: 'Overall drop-off',
                                value: `${Math.round(
                                    (((data?.filters ?? 0) - (data?.confirmation ?? 0)) / (data?.filters ?? 0)) * 100
                                )}%`
                            }
                        ]
                    } 
                    columns={
                        [
                            {
                                title: 'Name',
                                dataIndex: 'name',
                                key: 'name'
                            },
                            {
                                title: 'Value',
                                dataIndex: 'value',
                                key: 'value'
                            }
                        
                        ]
                    } 
                    pagination={false}
                />
                {/* <ol>

                    <li>
                        <label>Filters:</label> {data?.filters} (Completed Selecting an Appointment Type, and the Number of Pets):
                    </li>
                    <li>
                        <label>Timeslot:</label> {data?.timeslots} (Completed Selecting a Time Slot)
                    </li>
                    <li>
                        <label>Client Patient Details:</label> {data?.collectInformation} (Completed both Client and Patient Details)
                    </li>
                    <li>
                        <label>Confirmation:</label> {data?.confirmation} (Reached the confirmation page)
                    </li>
                    <li>
                        <label>Overall drop-off:</label> 
                        <span>
                            {
                                Math.round(
                                    (((data?.filters ?? 0) - (data?.confirmation ?? 0)) / (data?.filters ?? 0)) * 100
                                )
                            }% (Total Overall Dropoff Rate)
                        </span>
                    </li>
                </ol> */}
            </Card>
        </div>
    )
}

export default Dropoffs